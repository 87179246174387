/** global: Craft */
/** global: Garnish */
/**
 * DataTableSorter
 */
Craft.DataTableSorter = Garnish.DragSort.extend(
  {
    $table: null,

    init: function (table, settings) {
      this.$table = $(table);
      var $rows = this.$table.children('tbody').children(':not(.filler)');

      settings = $.extend({}, Craft.DataTableSorter.defaults, settings);

      settings.container = this.$table.children('tbody');
      settings.helper = this.getHelper.bind(this);
      settings.caboose = '<tr/>';
      settings.axis = Garnish.Y_AXIS;
      settings.magnetStrength = 4;
      settings.helperLagBase = 1.5;

      this.base($rows, settings);
    },

    getHelper: function ($helperRow) {
      var $helper = $(
          '<div class="' + this.settings.helperClass + '"/>'
        ).appendTo(Garnish.$bod),
        $table = $('<table/>').appendTo($helper),
        $tbody = $('<tbody/>').appendTo($table);

      $helperRow.appendTo($tbody);

      // Copy the table width and classes
      $table.width(this.$table.width());
      $table.prop('className', this.$table.prop('className'));

      // Copy the column widths
      var $firstRow = this.$table.find('tr:first'),
        $cells = $firstRow.children(),
        $helperCells = $helperRow.children();

      for (var i = 0; i < $helperCells.length; i++) {
        $($helperCells[i]).width($($cells[i]).width());
      }

      return $helper;
    },
  },
  {
    defaults: {
      handle: '.move',
      helperClass: 'datatablesorthelper',
    },
  }
);
